import { ApplicationState } from '~/redux/root-reducer';

if (typeof window !== 'undefined') {
    // eslint-disable-next-line no-underscore-dangle
    window.__main = (state: ApplicationState) => {
        import('./bootstrap').then(({ initialize }) => {
            initialize(state);
        });
    };
}
